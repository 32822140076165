import { AlertService } from './../alert/alert.service';
import { DataService } from './../shared/service/data.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
export interface edoVolume {
  volume_id: string;
  site: string;
  shift: string;
  date: string;
}
// const DATA: edoVolume[] = [
//   { site: 'M&S Crew', shift: 'Shift1', date: '22/02/2019' },
//   { site: 'M&S Crew', shift: 'Shift2', date: '22/02/2019' },
//   { site: 'Crew', shift: 'Shift5', date: '22/02/2019' },
// ];

@Component({
  selector: 'app-eod-volume',
  templateUrl: './eod-volume.component.html',
  styleUrls: ['./eod-volume.component.scss']
})
export class EdoVolumeComponent implements OnInit {
  public pageSize: any;
  public currentPage = 0;
  public totalSize = 0;
  pageindex: any;
  pagesize: any;
  isNodata: boolean = false;
  loading: boolean;
  searchNotfound: boolean = false;
  displayedColumns: string[] = ['select', 'site', 'shift', 'date', 'status', 'action'];
  selection = new SelectionModel<edoVolume>(true, []);
  dataSource: MatTableDataSource<edoVolume>;
  permissions: any;
  addBtn: boolean = false;
  bulkSubmitBtn: boolean = false;
  bulksubmitList: any;
  user_id: any;
  updated_byuser: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  eodList: any
  constructor(

    private EODservice: DataService,
    private alertMsg: AlertService,
    private router: Router,
    public route: ActivatedRoute) {
    console.log(this.selection.selected);
    this.user_id = JSON.parse(localStorage.getItem('userData'))
    this.updated_byuser = this.user_id.operative_id
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    if (this.permissions.system_role == 'HR' || this.permissions.system_role == 'Admin') {
      this.addBtn = false
      this.bulkSubmitBtn = false
    } else {
      this.addBtn = true
      this.bulkSubmitBtn = true
    }
    if (this.permissions.system_role == 'Supervisor') {
      this.bulkSubmitBtn = false
    }
    this.pageindex = this.route.snapshot.queryParams['pageIndex'] || localStorage.getItem('eodPageindex');
    this.pagesize = this.route.snapshot.queryParams['pagesize'] || localStorage.getItem('eodPagesize');
  }

  ngOnInit() {
    this.getEOD();
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  bulkSubmit() {
    if (this.selection.selected.length > 0) {
      console.log(this.selection.selected);
      this.bulksubmitList = 0;
      this.selection.selected.forEach(row => {
        this.bulksubmitList = this.bulksubmitList + ',' + row.volume_id
      });
      const data = {
        volume_ids: this.bulksubmitList,
        submitted_to: 'HR',
        updated_byuser: this.updated_byuser
      }
      console.log(data);
      this.EODservice.bulksbumit_eod(data).subscribe((data: any) => {
        console.log(data);
        if (data.status == "success") {
          this.alertMsg.openAlert(data.data, 'success');
          this.selection.clear();
          this.getEOD();
        } else if (data.status == "failure") {
          this.alertMsg.openAlert(data.data, 'info');
          this.selection.clear();
          this.getEOD();
        } else {
          this.alertMsg.openAlert(data.data, 'info');
          this.selection.clear();
        }
      })
    } else {
      this.alertMsg.openAlert('please select at least one record.', 'info');
    }
  }

  checkboxLabel(row?: edoVolume) {
    console.log(this.selection.selected.length);

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.volume_id + 1}`;
  }

  getEOD() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))

    const userdata = JSON.parse(localStorage.getItem('userData'))
    this.loading = true
    var data = {
      "site_id": userdata.site_id,
      "system_role": this.permissions.system_role,
    }
    this.EODservice.getEOD(data).subscribe((data: any) => {
      // console.log(data.data);
      this.isNodata = data.data
      if (data.status == "success") {
        localStorage.setItem('userToken', data.token);
        this.eodList = data.data
        if (this.eodList.length == 0) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
        this.dataSource = new MatTableDataSource(this.eodList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      } else if (data.session == 0) {
        var loginErr_Mag = "Session expired. Redirect to login"
        this.alertMsg.openAlert(loginErr_Mag, 'error');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('permissions');
        window.location.reload();
      } else if (data.status == "failure") {
        this.loading = false;
        this.isNodata = true
      }
    })

  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.searchNotfound = true
    } else {
      this.searchNotfound = false
    }
  }
  addedo() {
    this.router.navigate(['eod-volume/addEod'])
    // console.log(this.route);
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    localStorage.setItem('eodPagesize', e.pageSize);
    localStorage.setItem('eodPageindex', e.pageIndex);

  }
}
