import { UserService } from './../shared/userService/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer'


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})

export class MainNavComponent implements OnInit, OnDestroy {
  isloggedin: boolean;
  hr: boolean = false;
  manager: boolean = false;
  supervisor: boolean = false;
  userName: any;
  isHome: boolean;
  alive = true;
  permissions: any;
  activity: boolean = false
  operativ: boolean = false
  rate: boolean = false
  volume: boolean = false
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,

    private pagetitle: Title,
    public userService: UserService,
    private router: Router) {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    if (this.permissions) {
      if (this.permissions.activity_read == 1) { this.activity = true }
      if (this.permissions.operative_read == 1) { this.operativ = true }
      if (this.permissions.system_role == 'HR') { this.rate = true }
      if (this.permissions.volume_read == 1) { this.volume = true }
      if (this.permissions.system_role == 'Admin') {
        this.volume = true;
        this.activity = true;
      }
    }
    this.router.events.subscribe((val: any) => {
      // see also 
      if (val.url == "/home") {
        this.isHome = false;
      } else {
        this.isHome = true;
      }
    });
    const isHome = this.router.url
    if (isHome == "/home") {
      this.isHome = false;
    } else {
      this.isHome = true;
    }
  }
  public setTitle(newTitle: string) {
    this.pagetitle.setTitle(newTitle);
  }
  onClickUrl() {
    const isHome = this.router.url
    if (isHome == "/home") {
      this.isHome = false;

    } else {
      this.isHome = true;
    }
  }

  Logout() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('permissions')
    window.location.reload();
  }
  ngOnInit() {
    var user = JSON.parse(localStorage.getItem('userData'))
    this.userName = user.firstname + ' ' + user.lastname;
    // console.log(this.userName);
  }
  ngOnDestroy() {
    // ...
    const isHome = this.router.url
    if (isHome == "/home") {
      this.isHome = false;

    }
  }
}
