import { AlertService } from './../alert/alert.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '../shared/userService/user.service';


@Injectable({
    providedIn: 'root'
})
export class PageGuard implements CanActivate {
    permissions: any
    constructor(public auth: UserService,
        private alertMSG: AlertService,
        public router: Router) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.permissions = JSON.parse(localStorage.getItem('permissions'))
        if (this.permissions) {
            if (this.permissions.activity_read == 0) {
                var loginErr_Mag = "Access denied"
                this.alertMSG.openAlert(loginErr_Mag, 'error')
                this.router.navigate(['home']);
                return false;
            }
        }
        return true;
    }
}
