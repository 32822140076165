import { DatePipe } from '@angular/common';
import { AlertService } from './../alert/alert.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../shared/service/data.service';

export interface operativData {
  site: string;
  firstname: string;
  lastname: string;
  role: string;
  applicable_from: string;
  ///

}

@Component({
  selector: 'app-operativ',
  templateUrl: './operativ.component.html',
  styleUrls: ['./operativ.component.scss']
})
export class OperativComponent implements OnInit {
  ///
  permissions: any;
  public pageSize: any;
  public currentPage = 0;
  public totalSize = 0;
  isLoadingResults = false;
  ////
  today: Date = new Date();
  session: any = [];
  public data = [];
  public operativList: any
  userdata: any = [];
  usertoken: any;
  public odata: any;
  editEnable: boolean = true;
  date: any;
  loading: boolean;
  pageindex: any;
  pagesize: any;
  userRole: any;
  isNodata: boolean = false;
  searchNotfound: boolean = false;
  addBtn: boolean = false;
  displayedColumns: string[] = ['site', 'role', 'firstname', 'lastname', 'applicable_from', 'action'];
  dataSource: MatTableDataSource<operativData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router,
    public route: ActivatedRoute,
    public datepipe: DatePipe,
    private alertMsg: AlertService,
    private dataService: DataService) {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    this.pageindex = this.route.snapshot.queryParams['pageIndex'] || localStorage.getItem('operativPageindex');
    this.pagesize = this.route.snapshot.queryParams['pagesize'] || localStorage.getItem('operativPagesize');
    this.userRole = localStorage.getItem('userRoles')
    if (this.permissions) {
      if (this.permissions.operative_create == 1) {
        this.addBtn = true
      }
    }
  }

  ngOnInit() {
    this.getOperativ();
  }
  getOperativ() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    this.date = this.datepipe.transform(this.today, 'yyyy-MM-dd');
    this.userdata = JSON.parse(localStorage.getItem('userData'))
    this.usertoken = localStorage.getItem('userToken')
    var data = {
      "site_id": this.userdata.site_id,
      "system_role": this.permissions.system_role,
    }
    this.dataService.getOperativ(data).subscribe((data: any) => {
      this.loading = true;
      if (data.status == "success") {
        localStorage.setItem('userToken', data.token);
        this.odata = data;
        this.operativList = this.odata.data
        if (this.operativList.length == 0) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
        const date = this.datepipe.transform(this.today, 'yyyy-MM-dd');
        if (this.operativList.applicable_from <= date) {
          this.editEnable = false
        }
        this.dataSource = new MatTableDataSource(this.operativList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      } else if (data.session == 0) {
        var loginErr_Mag = "Session expired. Redirect to login"
        this.alertMsg.openAlert(loginErr_Mag, 'error');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('permissions')
        window.location.reload();
      } else if (data.status == "failure") {
        this.loading = false;
        this.isNodata = true
      }
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log(this.dataSource);
    if (this.dataSource.filteredData.length == 0) {
      this.searchNotfound = true
      console.log(this.searchNotfound);
    } else {
      this.searchNotfound = false
    }

  }
  add() {
    this.router.navigate(['operative/addOperative'])
  }
  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    localStorage.setItem('operativPagesize', e.pageSize);
    localStorage.setItem('operativPageindex', e.pageIndex);

  }
}
