import { MatSidenavModule } from '@angular/material/sidenav';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule, MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatNativeDateModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
} from '@angular/material';

@NgModule({
    imports: [
        CommonModule,
        MatPaginatorModule
    ],
    declarations: [],
    exports: [
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatSidenavModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatMenuModule,
        MatSnackBarModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule


    ],
    providers: [
        MatDatepickerModule,
        MatCheckboxChange,
    ],
})
export class NgmaterialModule { }