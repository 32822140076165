import { AlertService } from './../alert/alert.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/userService/user.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  permissions: any
  constructor(public auth: UserService,
    private alertMSG: AlertService,
    public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    if (this.permissions) {
      if (this.permissions.system_role !== 'HR') {
        var loginErr_Mag = "(Access denied) You don't have permission to access this page"
        this.alertMSG.openAlert(loginErr_Mag, 'error')
        this.router.navigate(['operative']);
        return false;
      }
      return true;
    }
    return true;
  }
}
