import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AlertService } from './alert/alert.service';
// import { DataService } from './shared/service/data.service';
import { AuthGuard } from './auth/auth.guard';
//imports-module
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { NgmaterialModule } from './shared/materialModule/material.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LayoutModule } from '@angular/cdk/layout';
// component
import { AppComponent } from './app.component';
import { ActivityComponent } from './activity/activity.component';
import { AddActivityComponent } from './activity/add-activity/add-activity.component';
// import { MainNavComponent } from './main-nav/main-nav.component';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { HomeComponent } from './home/home.component';
import { AppFooterComponent } from './common/app-footer/app-footer.component';
import { RateComponent } from './rate/rate.component';
import { RatedetailComponent } from './rate/ratedetail/ratedetail.component';
import { AddratemodalComponent } from './rate/addratemodal/addratemodal.component';
import { DataTabelComponent } from './shared/components/data-tabel/data-tabel.component';
import { EdoVolumeComponent } from './eod-volume/eod-volume.component';
import { OperativComponent } from './operativ/operativ.component';
import { AddOperativeComponent, RemoveUnderscorePipe, } from './operativ/add-operative/add-operative.component';
import { AddEdovolumeComponent } from './eod-volume/add-edovolume/add-edovolume.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './common/header/header.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MainNavComponent } from './main-nav/main-nav.component';
import { DataService } from './shared/service/data.service';
import { AlertComponent } from './alert/alert.component';
import { DisableControlDirective } from './shared/directive/disable-control.directive';
import { RequiredIfDirective } from './shared/directive/required-if.directive';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { MAT_SNACK_BAR_DATA, MAT_DATE_LOCALE } from '@angular/material';
import { DatePipe } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotfoundComponent,
    HomeComponent,
    AppFooterComponent,
    RateComponent,
    RatedetailComponent,
    AddratemodalComponent,
    MainNavComponent,
    ActivityComponent,
    AddActivityComponent,
    DataTabelComponent,
    EdoVolumeComponent,
    OperativComponent,
    AddOperativeComponent,
    AddEdovolumeComponent,
    HeaderComponent,
    AlertComponent,
    DisableControlDirective,
    RequiredIfDirective,
    ConfirmationModalComponent,
    RemoveUnderscorePipe,
  ],
  imports: [
    NgxMatSelectSearchModule,
    NgmaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule
  ],

  providers: [
    Title,
    AuthGuard,
    DataService,
    DatePipe,
    AlertService,
    RemoveUnderscorePipe,
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    // { provide: DateAdapter, useClass: MomentDateAdapter },
  ],
  entryComponents: [ConfirmationModalComponent, AlertComponent],
  bootstrap: [AppComponent]
})
export class AppModule {

}
