import { MatSnackBar } from '@angular/material';
import { AlertComponent } from './alert.component';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public alert: MatSnackBar) { }
  openAlert(message: string, panelClass: string) {
    this.alert.openFromComponent(AlertComponent, {
      data: message,
      panelClass: panelClass,
      duration: 3000
    });
  }
}
