import { AlertService } from './../alert/alert.service';
import { DataService } from './../shared/service/data.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
// import { DataTabelComponent } from './../shared/components/data-tabel/data-tabel.component';
export interface rate {
  site: string;
  role: string;
  shift: string;
  applicable_from: string;
};

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})

export class RateComponent implements OnInit {
  public pageSize: any;
  public currentPage = 0;
  public totalSize = 0;
  pageindex: any;
  pagesize: any;
  isNodata: boolean = false;
  loading: boolean;
  searchNotfound: boolean = false;
  displayedColumns: string[] = ['site', 'role', 'shift', 'applicable_from', 'action'];
  dataSource: MatTableDataSource<rate>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  rateList: any;
  constructor(private router: Router,
    private dataService: DataService,
    private alertMsg: AlertService,
    public route: ActivatedRoute) {
    this.pageindex = this.route.snapshot.queryParams['pageIndex'] || localStorage.getItem('pageindex');
    this.pagesize = this.route.snapshot.queryParams['pagesize'] || localStorage.getItem('pagesize');
  }

  ngOnInit() {
    this.getRate();
  }
  getRate() {
    return this.dataService.getRates().subscribe((data: any) => {
      this.loading = true;
      if (data.status == "success") {
        this.rateList = data.data;
        if (this.rateList.length == 0) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
        this.dataSource = new MatTableDataSource(this.rateList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      } else if (data.session == 0) {
        var loginErr_Mag = "Session expired. Redirect to login"
        this.alertMsg.openAlert(loginErr_Mag, 'error');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('permissions');
        window.location.reload();
      } else if (data.status == "failure") {
        this.loading = false;
        this.isNodata = true
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.searchNotfound = true
    } else {
      this.searchNotfound = false
    }
  }

  addRate() {
    this.router.navigate(['rate/add-new'])
    // console.log(this.route);
  }
  edit() {
    this.router.navigate(['rate/rateDetails'])
  }
  public handlePage(e: any) {
    // console.log(e);
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    localStorage.setItem('pagesize', e.pageSize);
    localStorage.setItem('pageindex', e.pageIndex);
  }

}

