import { AlertService } from './../alert/alert.service';
import { MatTableDataSource, MatPaginator, MatSort, PageEvent } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../shared/service/data.service';
import { SelectionModel } from '@angular/cdk/collections';
// import { DataTabelComponent } from './../shared/components/data-tabel/data-tabel.component';
export interface activity {
  daily_activity_id: string;
  date: string;
  site: string;
  firstname: string;
  lastname: string;
  role: string;
}
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
  pageindex: any;
  pagesize: any;
  userdata: any;
  activityList: any;
  isAdd: boolean = false;
  bulkSubmitBtn: boolean = false
  isNodata: boolean = false;
  loading: boolean;
  searchNotfound: boolean = false;
  permissions: any;
  role: any
  public array: any;
  forManager: boolean = false;
  forSupervisor: boolean = false;
  displayedColumns: string[] = ['select', 'date', 'site', 'firstname', 'lastname', 'role', 'agency', 'shift', 'hours', 'status', 'action'];
  dataSource: MatTableDataSource<activity>;
  selection = new SelectionModel<activity>(true, []);
  bulksubmitList: any;
  user_id: any;
  updated_byuser: any;
  selectionAmount: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('ref') ref: any;
  constructor(private router: Router,
    private activity: DataService,
    private alertMsg: AlertService,
    public route: ActivatedRoute) {
    this.pageindex = this.route.snapshot.queryParams['pageIndex'] || localStorage.getItem('activityPageindex');
    this.pagesize = this.route.snapshot.queryParams['pagesize'] || localStorage.getItem('activityPagesize');
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    this.user_id = JSON.parse(localStorage.getItem('userData'))
    this.updated_byuser = this.user_id.operative_id
    this.role = this.permissions.system_role
    console.log(this.role);

    if (this.role == 'HR') {
      this.forManager = true
    }
    if (this.role == 'Manager') {
      this.forManager = true
    }
    if (this.role == 'Supervisor') {
      this.forSupervisor = true
    }

  }

  ngOnInit() {
    this.getActivity();

  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    console.log(this.selection.selected.length);
    const numRows = this.dataSource.data.length;
    return numSelected === 5;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(_ref: any) {
    this.isAllSelected() ?
      this.selection.clear() : this.selectRows();
  }
  checkboxLabel(row?: activity) {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.daily_activity_id + 1}`;
  }

  selectRows() {

    let i = this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize;
    let end = (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize;
    for (i; i < end; i++) {
      this.selection.select(this.dataSource.data[i]);
      console.log(this.selection.select(this.dataSource.data[i]));
    }
  }
  bulkSubmit() {
    if (this.selection.selected.length > 0) {
      this.bulksubmitList = 0;
      this.selection.selected.forEach(row => {
        this.bulksubmitList = this.bulksubmitList + ',' + row.daily_activity_id
      });
      const data = {
        daily_activity_ids: this.bulksubmitList,
        submitted_to: 'HR',
        updated_byuser: this.updated_byuser
      }
      console.log(data);
      this.activity.bulksbumit_activities(data).subscribe((data: any) => {
        if (data.status == "success") {
          this.alertMsg.openAlert(data.data, 'success');
          this.selection.clear();
          this.getActivity();
        } else if (data.status == "failure") {
          this.alertMsg.openAlert(data.data, 'info');
          this.selection.clear();
          this.getActivity();
        } else {
          this.alertMsg.openAlert(data.data, 'info');
          this.selection.clear();
        }
      })
    } else {
      this.alertMsg.openAlert('please select at least one record.', 'info');
    }
  }




  getActivity() {
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    this.userdata = JSON.parse(localStorage.getItem('userData'))
    this.loading = true
    // console.log(this.userdata.role);
    if (permissions.activity_create == 1) {
      if (permissions.system_role == 'HR' || permissions.system_role == 'Admin') {
        this.isAdd = false;
        this.bulkSubmitBtn = false;
      } else {
        this.isAdd = true;
        this.bulkSubmitBtn = true;
      }

      if (permissions.system_role == 'Supervisor') {
        this.bulkSubmitBtn = false;
      }
    }
    var data = {
      "site_id": this.userdata.site_id,
      "system_role": permissions.system_role,
    }
    this.activity.activityList(data).subscribe((data: any) => {
      // console.log(data);
      if (data.status == "success") {
        localStorage.setItem('userToken', data.token);
        this.activityList = data.data
        if (this.activityList.length == 0) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
        // console.log(this.activityList);
        // if (permissions.system_role == 'Supervisor') {
        //   const submit = this.activityList.filter((x: any) => x.submitted_to == 'HR' || 'Manager')
        //   console.log(submit);
        // }
        this.dataSource = new MatTableDataSource(this.activityList);
        // console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;

      } else if (data.session == 0) {
        var loginErr_Mag = "Session expired. Redirect to login"
        this.alertMsg.openAlert(loginErr_Mag, 'error');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('userRoles');
        window.location.reload();
      } else if (data.status == "failure") {
        this.loading = false;
        this.isNodata = true
      }

    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this.searchNotfound = true
    } else {
      this.searchNotfound = false
    }
  }

  addActivity() {
    this.router.navigate(['dailyActiviti/addActiviti'])
    // console.log(this.route);
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    localStorage.setItem('activityPagesize', e.pageSize);
    localStorage.setItem('activityPageindex', e.pageIndex);
    // this.iterator

  }
}
