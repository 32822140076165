import { Component, OnInit, Input, ViewChild, Output, EventEmitter, } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-data-tabel',
  templateUrl: './data-tabel.component.html',
  styleUrls: ['./data-tabel.component.scss']
})
export class DataTabelComponent implements OnInit {

  constructor() { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() tableData: any;
  @Input() columnHeader: any;
  @Output() id: EventEmitter<string> = new EventEmitter();

  @Input() dataSource: any;

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    console.log(this.tableData);
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  rowDetails(element) {
    this.id.emit(element);

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
