import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl = environment.baseURL;
  private loggedIn = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }
  userAuthentication(username, password) {
    var data = {
      "username": username,
      "password": password
    }
    // "username=" + username + "&password=" + password;
    // console.log(data);
    var reqHeader = new HttpHeaders({ 'Content-Type': 'raw' });
    return this.http.post(this.apiUrl + 'login', data, { headers: reqHeader });
    this.loggedIn.next(true)
  }
  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var userRoles: string[] = JSON.parse(localStorage.getItem('userRoles'));
    allowedRoles.forEach(element => {
      if (userRoles.indexOf(element) > -1) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }
  resetPassword(username, password, operative_id) {
    var data = {
      "username": username,
      "password": password,
      "operative_id": operative_id,
    }
    var reqHeader = new HttpHeaders({ 'Content-Type': 'raw' });
    return this.http.post(this.apiUrl + 'reset_password', data, { headers: reqHeader });
  }
}
