import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addratemodal',
  templateUrl: './addratemodal.component.html',
  styleUrls: ['./addratemodal.component.scss']
})
export class AddratemodalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
