import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfirmationModalComponent } from './../../shared/components/confirmation-modal/confirmation-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from './../../alert/alert.service';
import { DataService } from './../../shared/service/data.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-edovolume',
  templateUrl: './add-edovolume.component.html',
  styleUrls: ['./add-edovolume.component.scss']
})
export class AddEdovolumeComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmationModalComponent>
  public activityFilter: FormControl = new FormControl();
  public contractFilter: FormControl = new FormControl();
  isSubmit: boolean = false
  volumeID: any
  registerForm: FormGroup;
  activity_id: any;
  addNew: any;
  siteList: any;
  shiftList: any;
  eodData: any;
  eod_by_id: any;
  eod_activity = [];
  activityList: any;
  activity_filter_List: any;
  contract_filter_List: any;
  contractList: any;
  equipmentList: any;
  equipmentList_by_contract_id = [];
  user: any;
  alertMessage: any;
  operative_role: any;
  valid: any;
  creds: any;
  Userole: any;
  isSeletedIndex: any
  equipment: any
  req: boolean = false;
  contract: any;
  input: any;
  value: any;
  volume: any;
  setSite: any;
  userID: any;
  siteListBy_siteid: any
  volumeReq: boolean = false;
  pageindex: any;
  pagesize: any;
  today: Date = new Date();
  date: any;
  permissions: any;
  edit: boolean = false;
  cerate: boolean = false;
  delete: boolean = false;
  admin: boolean = true;
  a: boolean = false;
  b: boolean = false
  constructor(
    private EODservice: DataService,
    private datepipe: DatePipe,
    private alertMsg: AlertService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    if (this.permissions.volume_edit == 1) { this.edit = true }
    if (this.permissions.system_role == 'Admin') { this.admin = false }
    if (this.permissions.volume_create == 1) { this.cerate = true }
    if (this.permissions.volume_delete == 1) { this.delete = true }
    this.pageindex = this.route.snapshot.queryParams['pageIndex']
    this.pagesize = this.route.snapshot.queryParams['pagesize']
    this.volumeID = this.route.snapshot.params['id']
    this.user = JSON.parse(localStorage.getItem('userData'));
    this.operative_role = this.user.role
  }
  applyactivityFilter(i) {
    console.log(i);
    console.log(this.activityFilter);



    var filterValue = this.activityFilter.value.trim().toLowerCase();
    // this.optionBExtra.
    if (filterValue) {
      this.a = true;
    } else {
      this.a = false;
    }
    // console.log(filterValue);
    // this.activityFilter.

    this.activity_filter_List = this.activityList.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0)


  }
  applycontractFilter() {
    const contractValue = this.contractFilter.value.trim().toLowerCase();

    if (contractValue) {
      this.b = true;
    } else {
      this.b = false;
    }
    this.contract_filter_List = this.contractList.filter(state => state.contract.toLowerCase().indexOf(contractValue) === 0);
  }


  ngOnInit() {
    this.date = this.datepipe.transform(this.today, 'yyyy-MM-dd');
    const site = JSON.parse(localStorage.getItem('userData'));
    if (this.permissions.system_role == 'Manager') {
      this.setSite = site.site_id;
      // console.log(this.setSite);
    } else if (this.permissions.system_role == 'Supervisor') {
      this.setSite = site.site_id;
      // console.log(this.setSite);
    } else {
      this.setSite = '';
      // console.log(this.setSite);
    }
    this.getShift_Site();
    this.getEodVolume();
    this.registerForm = this.formBuilder.group({
      site_id: [this.setSite, Validators.compose([Validators.required])],
      shift_id: ['', Validators.compose([Validators.required])],
      volume_date: ['', Validators.compose([Validators.required])],
      eod_volume_activities: this.formBuilder.array([]),
      updated_byuser: this.user.operative_id,
      submitted_to: [''],
      volume_id: ['']
    });
    // this.equipmentList_by_contract_id[i];
    console.log(this.equipmentList_by_contract_id);
    if (!this.volumeID) {
      for (let i = 0; i < 6; i++) {
        this.addCreds();

      }
    }
  }
  getEodVolume() {
    var data = {
      "volume_id": this.volumeID
    }
    if (this.volumeID) {
      this.EODservice.getEOD_byID(data).subscribe((data: any) => {
        if (data.status == "success") {
          this.eodData = data.data;
          this.eod_by_id = this.eodData.data
          // console.log(this.eod_by_id);
          this.Userole = localStorage.getItem('userRoles');
          // console.log(this.Userole);
          if (this.permissions.system_role == "Supervisor") {
            if (this.eod_by_id.submitted_to == 'HR' || this.eod_by_id.submitted_to == 'Manager') {
              this.registerForm.disable();
              this.isSubmit = true;
            }
          } else if (this.permissions.system_role == "Manager") {
            if (this.eod_by_id.submitted_to == 'HR') {
              this.registerForm.disable();
              this.isSubmit = true;
            }
          }
          this.registerForm = this.formBuilder.group({
            site_id: this.eod_by_id.site_id,
            shift_id: this.eod_by_id.shift_id,
            volume_date: this.eod_by_id.date,
            updated_byuser: this.user.operative_id,
            volume_id: this.eod_by_id.volume_id,
            submitted_to: [''],
            eod_volume_activities: this.formBuilder.array([]),
          });
          this.setActivity();
        }
      });

    }

  }
  addCreds() {
    const creds = this.registerForm.controls.eod_volume_activities as FormArray;
    creds.push(this.formBuilder.group({
      activity_id: [''],
      contract_id: [''],
      equipment_id: [''],
      volume: ['']
    }));
    // console.log(creds);
    // this.addActivity = creds.value
  }
  getIndex(index) {
    // console.log(index);
    this.isSeletedIndex = index;
  }
  onSelectActivty(i) {
    this.activity_filter_List = this.activityList
    // console.log(i);
    this.activityInput.updateValueAndValidity();
    this.valid = this.activityList.filter((x: any) => x.activity_id == i)
    // console.log(this.valid);

    if (i !== undefined) {
      this.contractInput.setValidators(Validators.required);
      this.contractInput.updateValueAndValidity();
      if (this.valid[0].eod_equipment_needed == '1' && this.valid[0].eod_volume_needed == '1') {
        // console.log(this.volumeInput);

        this.volumeReq = true;
        // console.log(this.volumeReq);
        this.equipmentInput.setValidators(Validators.required);
        this.volumeInput.setValidators(Validators.required);
        this.equipmentInput.updateValueAndValidity();
        this.volumeInput.updateValueAndValidity();
      } else if (this.valid[0].eod_equipment_needed == '1' && this.valid[0].eod_volume_needed == '0') {
        this.equipmentInput.setValidators(Validators.required);
        this.volumeInput.setValidators(Validators.nullValidator);
        this.equipmentInput.updateValueAndValidity();
        this.volumeInput.updateValueAndValidity();
      } else if (this.valid[0].eod_equipment_needed == '0' && this.valid[0].eod_volume_needed == '1') {
        this.volumeInput.setValidators(Validators.required);
        this.volumeReq = true;
        // console.log(this.volumeReq);
        this.equipmentInput.setValidators(Validators.nullValidator);
        this.equipmentInput.updateValueAndValidity();
        this.volumeInput.updateValueAndValidity();
      } else if (this.valid[0].eod_equipment_needed == '0' && this.valid[0].eod_volume_needed == '0') {
        this.equipmentInput.setValidators(Validators.nullValidator);
        this.equipmentInput.updateValueAndValidity();
        this.volumeInput.setValidators(Validators.nullValidator);
        this.volumeInput.updateValueAndValidity();
      }
    }
    else if (i == undefined) {
      this.contractInput.setValidators(Validators.nullValidator);
      this.equipmentInput.setValidators(Validators.nullValidator);
      this.volumeInput.setValidators(Validators.nullValidator);
      // this.equipmentInput.reset();
      // this.volumeInput.reset();
      // this.contractInput.reset();

    }
  }
  onContract(i, index) {
    this.contract_filter_List = this.contractList
    // console.log(i, index);
    // this.siteListBy_siteid = this.siteList.filter(site => site.site_id == site_id)
    this.equipmentList_by_contract_id[index] = this.equipmentList.filter(equipment => equipment.contract_id == i)
    console.log(this.equipmentList_by_contract_id[index]);

    if (this.equipmentList_by_contract_id[index].length == 0) {
      this.alertMsg.openAlert("Equipment type not found for this contract", 'info');
    }

  }
  get activityInput() {
    return this.optionBExtra.get('activity_id') as FormControl;
  }
  get equipmentInput() {
    return this.optionBExtra.get('equipment_id') as FormControl;
  }
  get volumeInput() {
    return this.optionBExtra.get('volume') as FormControl;
  }
  get contractInput() {
    return this.optionBExtra.get('contract_id') as FormControl;
  }
  get optionBExtra() {
    return (<FormArray>this.registerForm.get('eod_volume_activities')).controls[this.isSeletedIndex];
  }

  getControls() {
    return (<FormArray>this.registerForm.get('eod_volume_activities')).controls;
  }
  setActivity() {
    const creds = this.registerForm.controls.eod_volume_activities as FormArray;
    this.eod_activity = this.eod_by_id.volume_activities
    console.log(this.eod_activity);
    var i = 0;
    this.eod_activity.forEach((x: any) => {
      creds.push(this.formBuilder.group({
        activity_id: x.activity_id,
        contract_id: x.contract_id,
        equipment_id: x.equipment_id,
        volume: x.volume
      }))
      this.equipmentList_by_contract_id[i] = this.equipmentList.filter(equipment => equipment.contract_id == x.contract_id)
      i++
    })
    console.log(this.equipmentList_by_contract_id);


  }
  deleteVolumeArray(index) {
    const creds = this.registerForm.controls.eod_volume_activities as FormArray;
    creds.removeAt(index)

  }
  deleteVolumeActivity(index) {
    console.log(this.eod_activity[index].eod_volume_activity_id);
    const ID = { "eod_volume_activity_id": this.eod_activity[index].eod_volume_activity_id }
    console.log(ID);

    this.EODservice.daelete_EOD_ActivityList_byID(ID).subscribe((data: any) => {
      console.log(data);

      if (data.status == "success") {
        const creds = this.registerForm.controls.eod_volume_activities as FormArray;
        creds.removeAt(index)
        this.alertMessage = data.data;
        this.alertMsg.openAlert(this.alertMessage, 'success');
      } else if (data.status == "failure") {
        this.alertMessage = data.error;
        this.alertMsg.openAlert(this.alertMessage, 'error');
      }
    })


  }
  getShift_Site() {
    const site = JSON.parse(localStorage.getItem('userData'));
    const site_id = site.site_id
    var data = { "volume_id": "new", }
    this.EODservice.getEOD_byID(data).subscribe((data: any) => {
      if (data.status == "success") {
        localStorage.setItem('userToken', data.token);
        console.log(data);
        this.addNew = data.data;
        this.siteList = this.addNew.siteList;
        if (this.permissions.system_role == 'Manager' || this.permissions.system_role == 'Supervisor') {
          this.siteListBy_siteid = this.siteList.filter(site => site.site_id == site_id)
        } else {
          this.siteListBy_siteid = this.siteList
        }
        this.shiftList = this.addNew.shiftList;
        this.activityList = this.addNew.activityList;
        this.activity_filter_List = this.activityList;
        this.contractList = this.addNew.contractList;
        this.contract_filter_List = this.contractList
        console.log(this.contractList);

        this.equipmentList = this.addNew.equipmentList;
        console.log(this.equipmentList);



      } else if (data.session == 0) {
        var loginErr_Mag = "Session expired. Redirect to login"
        this.alertMsg.openAlert(loginErr_Mag, 'error');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('permissions')
        window.location.reload();
      }
    });
  }
  addEOD_Volume() {
    var date = this.datepipe.transform(this.registerForm.controls.volume_date.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ volume_date: date });
    console.log(this.registerForm.value);
    this.EODservice.addEodVolume(this.registerForm.value).subscribe((data: any) => {
      console.log(data);
      if (data.status == "success") {
        this.alertMessage = data.data;
        this.router.navigate(['eod-volume']);
        this.alertMsg.openAlert(this.alertMessage, 'success');
        this.registerForm.reset();
      } else if (data.status == "failure") {
        this.alertMessage = data.error;
        this.alertMsg.openAlert(this.alertMessage, 'error');
      }
    });
  }

  updateEOD_Volume() {
    var date = this.datepipe.transform(this.registerForm.controls.volume_date.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ volume_date: date });
    console.log(this.registerForm.value);

    this.EODservice.updateEodVolume(this.registerForm.value).subscribe((data: any) => {
      if (data.status == "success") {
        this.alertMessage = data.data;
        this.router.navigate(['eod-volume']);
        this.alertMsg.openAlert(this.alertMessage, 'success');
      } else if (data.status == "failure") {
        this.alertMessage = data.error;
        this.alertMsg.openAlert(this.alertMessage, 'error');
      }
    })
  }
  saveSubmitEOD() {
    if (this.permissions.system_role == 'Supervisor') {
      var submitted_to = "Manager"
    } else if (this.permissions.system_role == 'Manager') {
      var submitted_to = "HR"
    }
    var date = this.datepipe.transform(this.registerForm.controls.volume_date.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ volume_date: date });
    this.registerForm.patchValue({ submitted_to: submitted_to });
    console.log(this.registerForm.value);
    this.EODservice.addEodVolume(this.registerForm.value).subscribe((data: any) => {
      // console.log(data);
      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.ngOnInit()
        this.alertMessage = data.data;
        this.alertMsg.openAlert(this.alertMessage, 'success')
      } else if (data.status == "failure") {
        this.alertMessage = data.error;
        this.alertMsg.openAlert(this.alertMessage, 'error')
      }
    }, error => {
      // console.log(error);
    });
  }

  // submit activity end
  updateSubmit() {
    if (this.permissions.system_role == 'Supervisor') {
      var submitted_to = "Manager"
    } else if (this.permissions.system_role == 'Manager') {
      var submitted_to = "HR"
    }
    this.registerForm.patchValue({ submitted_to: submitted_to });
    var date = this.datepipe.transform(this.registerForm.controls.volume_date.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ volume_date: date });
    console.log(this.registerForm.value);
    this.EODservice.updateEodVolume(this.registerForm.value).subscribe((data: any) => {
      // console.log(data);
      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.alertMessage = data.data;
        this.router.navigate(['eod-volume']);
        this.alertMsg.openAlert(this.alertMessage, 'success');
        // this.registerForm.reset();
      } else if (data.status == "failure") {
        this.alertMessage = data.error;
        this.alertMsg.openAlert(this.alertMessage, 'error');
      }
    })
  }
  deleteEOD() {
    const data = {
      "volume_id": this.volumeID,
      "updated_byuser": this.user.operative_id
    }
    // console.log(data);

    this.EODservice.daelete_EOD(data).subscribe((data: any) => {
      if (data.status == "success") {
        this.alertMessage = data.data;
        this.router.navigate(['eod-volume']);
        this.alertMsg.openAlert(this.alertMessage, 'success');
        // this.registerForm.reset();
      } else if (data.status == "failure") {
        this.alertMessage = data.error;
        this.alertMsg.openAlert(this.alertMessage, 'error');
      }
    });
  }
  deleteVolumeActivityCnf(index): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteVolumeActivity(index);
      }
      this.dialogRef = null;
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteEOD();
      }
      this.dialogRef = null;
    });
  }
}
