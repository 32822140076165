import { DatePipe } from '@angular/common';
import { AlertService } from './../../alert/alert.service';
import { ConfirmationModalComponent } from './../../shared/components/confirmation-modal/confirmation-modal.component';
import { DataService } from './../../shared/service/data.service';
import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/[" "]/g, '.');
  }
}
@Component({
  selector: 'app-add-operative',
  templateUrl: './add-operative.component.html',
  styleUrls: ['./add-operative.component.scss']
})
export class AddOperativeComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmationModalComponent>
  @ViewChild('snackBarTemplate')
  hide = true;
  public message: string;
  operativID: any;
  operativDataBy_Id: any;
  operativDataById: any;
  operativData: any = []
  registerForm: FormGroup;
  addNew: any;
  site: any;
  siteList: any;
  role: any;
  roleList: any;
  additionalSetting: any;
  additionalSettingList: any;
  agency: any
  isAgencyReq: boolean = false;
  showPayrole: boolean = true;
  agencyList: any
  userID: any = {};
  firstname: string;
  applicable_from: any
  lastname: string;
  operative_id: any;
  reset_Password: boolean = false;
  has_system_Access: boolean = false;
  is_edit: boolean = true
  today: Date = new Date();
  date: any;
  transaction_Type: any;
  values = '';
  fnameuser: any;
  lnameuser: any;
  operativUserName: any;
  editEnabled: boolean = true;
  username: string
  password: string
  fusername: any;
  lpassword: any;
  userName: any;
  Applicable_from: any;
  reset_Password_set: boolean;
  has_system_Access_set: boolean;
  notRequired: boolean;
  future_records: any;
  passwordFieldRequired: boolean;
  passwordReset_by_user: boolean;
  siteListBy_siteid: any;
  isDelete: boolean = true;
  role_id: any;
  setSite: any;
  oldDate: any;
  toDaydate: any;
  pageindex: any;
  pagesize: any;
  permissions: any;
  edit: boolean = false;
  cerate: boolean = false;
  delete: boolean = false;
  constructor(
    public dotPipe: RemoveUnderscorePipe,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private operativ: DataService,
    public snackBar: MatSnackBar,
    private alertMsg: AlertService,
    public datepipe: DatePipe,
    private router: Router) {
    this.operativID = this.route.snapshot.params['id']
    this.pageindex = this.route.snapshot.queryParams['pageIndex']
    this.pagesize = this.route.snapshot.queryParams['pagesize']
    // console.log(this.pageindex);
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    if (this.permissions.operative_edit == 1) { this.edit = true }
    if (this.permissions.operative_create == 1) { this.cerate = true }
    if (this.permissions.operative_delete == 1) { this.delete = true }
    this.toDaydate = this.datepipe.transform(this.today, 'dd/MM/yyyy');
  }
  update(value: string) {
    console.log(value);

    this.userName = value
    if (this.operativID) {
      this.Applicable_from = value;
      this.oldDate = this.datepipe.transform(this.operativDataBy_Id.applicable_from, 'dd/MM/yyyy');
      if (this.oldDate == this.Applicable_from) {
        this.registerForm.patchValue({ transactionType: 'update' })
      } else if (this.oldDate > this.Applicable_from || this.oldDate > this.toDaydate) {
        this.registerForm.patchValue({ transactionType: 'update' })
      } else if (this.oldDate < this.Applicable_from && this.oldDate <= this.toDaydate) {
        this.registerForm.patchValue({ transactionType: 'insert' })
      }
    }
  }
  isDisabled(): boolean {
    if (this.operativID) {
      return this.is_edit;
    } else {
      return false
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteOperativ();
      }
      this.dialogRef = null;
    });
  }
  ngOnInit() {
    this.getOperativ();
    this.getRoleSiteAdditionalSetting();
    this.userID = JSON.parse(localStorage.getItem('userData'));
    if (this.permissions.system_role == 'Manager') {
      this.setSite = this.userID.site_id;
      this.isAgencyReq = true;
      this.showPayrole = false
      // console.log(this.isAgencyReq);
    } else if (this.permissions.system_role == 'Supervisor') {
      this.setSite = this.userID.site_id;
      this.isAgencyReq = true;
      this.showPayrole = false
      // console.log(this.isAgencyReq);
    } else {
      this.setSite = '';
    }


    this.operative_id = this.userID.operative_id;
    this.role_id = this.userID.role_id
    this.date = this.datepipe.transform(this.today, 'yyyy-MM-dd');
    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.compose([Validators.required, Validators.pattern(/^[^-\s]([a-zA-Z]*)+$/)])],
      lastname: ['', Validators.compose([Validators.required, Validators.pattern(/^[^-\s]([a-zA-Z0-9]*)+$/)])],
      payroll_no: ['', Validators.compose([Validators.pattern(/^[^-\s]([a-zA-Z0-9]*)+$/)])],
      agency_id: [''],
      role: ['', Validators.required],
      site: [this.setSite, Validators.required],
      operative_id: [''],
      additional_setting: [''],
      applicable_from: ['', Validators.required],
      has_system_access: this.has_system_Access,
      username: '',
      reset_password: this.reset_Password,
      password: '',
      status: ['active', Validators.required],
      updated_byuser: this.operative_id,
      op_role_id: [''],
      transactionType: [''],
      role_id: this.role_id,
      created_byuser: this.role_id,
      system_role: this.permissions.system_role
    });
  }
  onSubmit() {
  }
  setUsername(e) {
    // console.log(e);
    this.hide = true;
    if (!this.operativID) {
      this.registerForm.patchValue({
        username: this.dotPipe.transform(this.firstname + '.' + this.lastname),
        password: this.dotPipe.transform(this.firstname + '.' + this.lastname),
        reset_password: this.has_system_Access
      })
    }
  }
  getRoleSiteAdditionalSetting() {
    const site = JSON.parse(localStorage.getItem('userData'));
    const site_id = site.site_id
    var data =
      { "operative_id": "new" }
    this.operativ.newOperativ(data).subscribe((data: any) => {
      if (data.status == "success") {
        localStorage.setItem('userToken', data.token);
        // console.log(data);
        this.addNew = data;
        // for role
        this.role = this.addNew.data;
        this.roleList = this.role.roleList
        //for site
        this.site = this.addNew.data;
        this.siteList = this.site.siteList;
        if (this.permissions.system_role == 'Manager' || this.permissions.system_role == 'Supervisor') {
          this.siteListBy_siteid = this.siteList.filter(site => site.site_id == site_id)
        } else {
          this.siteListBy_siteid = this.siteList
        }

        //for additional_setting
        this.additionalSetting = this.addNew.data;
        this.additionalSettingList = this.additionalSetting.additionalSettingList;
        //for angecy
        this.agency = this.addNew.data;
        this.agencyList = this.agency.agencies;
        // console.log(this.agencyList);
      } else if (data.session == 0) {
        var loginErr_Mag = "Session expired. Redirect to login"
        this.alertMsg.openAlert(loginErr_Mag, 'error');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('permissions')
        window.location.reload();
      }
    });
  }
  // Add operative
  addOperativ() {
    // console.log(this.registerForm.value);
    var date = this.datepipe.transform(this.registerForm.controls.applicable_from.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ applicable_from: date });
    this.operativ.addOperativ(this.registerForm.getRawValue()).subscribe((data: any) => {
      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.message = 'New operative added';
        this.alertMsg.openAlert(this.message, 'success');
        this.router.navigate(['operative']);
      } else if (data.status == "failure") {
        this.message = data.error;
        this.alertMsg.openAlert(this.message, 'error');
      }
    }, error => {
      // console.log(error);
    });
  }
  updateOperative() {
    console.log(this.registerForm.getRawValue());
    var date = this.datepipe.transform(this.registerForm.controls.applicable_from.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ applicable_from: date });
    this.operativ.updateOperativ(this.registerForm.getRawValue()).subscribe((data: any) => {
      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.message = data.data;
        this.alertMsg.openAlert(this.message, 'success')
        this.router.navigate(['operative']);
      } else if (data.status == "failure") {
        this.message = data.error;
        this.alertMsg.openAlert(this.message, 'error');
      }
    }, error => {
      // console.log(error);
    });
  }

  getOperativ() {
    var data =
    {
      "op_role_id": this.route.snapshot.params['id'],
    }
    if (this.operativID) {
      this.operativ.editOperativ_byID(data).subscribe((data: any) => {
        this.operativData = data
        console.log(this.operativData.data);
        localStorage.setItem('userToken', this.operativData.token);
        this.operativDataById = this.operativData.data
        this.future_records = this.operativDataById.future_records
        this.operativDataBy_Id = this.operativDataById.data;
        if (this.role_id == '1') {
          this.registerForm.enable();
          this.registerForm.controls.payroll_no.disable()

        } else if (this.operativDataBy_Id.created_byuser == '3') {
          if (this.role_id == '2' || '3') {

            this.registerForm.enable();
          } else {
            this.registerForm.disable();
            this.isDelete = false
          }

        } else if (this.operativDataBy_Id.created_byuser == '2') {
          if (this.role_id == '2' || '3') {

            this.registerForm.enable();
          } else {
            this.registerForm.disable();
            this.isDelete = false
          }

        }
        else if (this.operativDataBy_Id.created_byuser !== this.role_id) {
          this.registerForm.disable();
          this.isDelete = false
        }

        if (this.operativDataBy_Id.created_byuser !== '1') {
        }
        // console.log(this.future_records.length);

        if (this.operativDataBy_Id.applicable_from < this.toDaydate) {

          this.registerForm.controls['applicable_from'].setValidators(null)
        }


        if (this.future_records.length == '0') {
          this.editEnabled = true;
        }
        else if (this.future_records.applicable_from > this.operativDataBy_Id.applicable_from) {
          this.editEnabled = false;
        } else if (this.future_records.applicable_from = this.operativDataBy_Id.applicable_from) {
          this.editEnabled = true;
        }
        if (this.operativDataBy_Id.reset_password == 1) {
          this.passwordReset_by_user = true;
        } else if (this.operativDataBy_Id.reset_password == 0) {
          this.passwordReset_by_user = false;
        }
        if (this.operativDataBy_Id.is_password_reset == 1) {
          this.reset_Password_set = true;
        }
        if (this.operativDataBy_Id.has_system_access == 1) {
          this.has_system_Access_set = true;
        } else {
          this.has_system_Access_set = false;
        }
        if (this.operativDataBy_Id.applicable_from) {
          this.transaction_Type = "update"
        }
        // if (this.operativDataBy_Id.applicable_from < this.date) {
        //   this.editEnabled = false;
        // }
        if (this.operativDataBy_Id.password) {
          this.notRequired = true;
        }
        this.registerForm.setValue({
          firstname: this.operativDataBy_Id.firstname,
          lastname: this.operativDataBy_Id.lastname,
          payroll_no: this.operativDataBy_Id.payroll_no,
          agency_id: this.operativDataBy_Id.agency_id,
          role: this.operativDataBy_Id.role_id,
          site: this.operativDataBy_Id.site_id,
          operative_id: this.operativDataBy_Id.operative_id,
          additional_setting: this.operativDataBy_Id.OperativeAdditionalSettings,
          applicable_from: this.operativDataBy_Id.applicable_from,
          has_system_access: this.has_system_Access_set,
          username: this.operativDataBy_Id.username,
          password: '',
          reset_password: this.passwordReset_by_user,
          status: this.operativDataBy_Id.status,
          updated_byuser: this.operative_id,
          op_role_id: this.route.snapshot.params['id'],
          transactionType: this.transaction_Type,
          role_id: this.role_id,
          created_byuser: this.operativDataBy_Id.created_byuser,
          system_role: this.permissions.system_role,
        });
      });
    }
  }
  // delete operative
  deleteOperativ() {
    var data = {
      "op_role_id": this.route.snapshot.params['id'],
      "updated_byuser": this.operative_id
    }
    this.operativ.daleteOperativ(data).subscribe((data: any) => {
      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.router.navigate(['operative']);
        this.message = data.data;
        this.alertMsg.openAlert(this.message, 'success')
      } else if (data.status == "failure") {
        this.message = data.error;
        this.alertMsg.openAlert(this.message, 'error')
      }
    }, error => {
      // console.log(error);
    });
  }
}
