import { ConfirmationModalComponent } from './../../shared/components/confirmation-modal/confirmation-modal.component';
import { AlertService } from './../../alert/alert.service';
import { DatePipe } from '@angular/common';
import { DataService } from './../../shared/service/data.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-ratedetail',
  templateUrl: './ratedetail.component.html',
  styleUrls: ['./ratedetail.component.scss']
})
export class RatedetailComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmationModalComponent>
  registerForm: FormGroup;
  uplift: FormGroup;
  addNew: any;
  site: any;
  siteList: any;
  role: any;
  roleList: any;
  role2: any;
  role2Upliftrate: any;
  selectedRole: number = 0;
  selectedRolemanager: number = 0;
  manager: any;
  supervisor: any;
  shiftList: any;
  userID: any;
  operative_id: any;
  managerID: any;
  supervisorID: any;
  rateDataById: any;
  rateID: any;
  upliftRateByID: any;
  oldDate: any;
  Applicable_from: any;
  ID: any;
  transaction_Type: any;
  editFalse: boolean = false;
  permissions: any;
  edit: boolean = false;
  cerate: boolean = false;
  rateDelete: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    // private uplift: 
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private alertMsg: AlertService,
    public datepipe: DatePipe,
    private rate: DataService,
    private router: Router
  ) {
    this.rateID = this.route.snapshot.params['id']
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    if (this.permissions.rate_edit == 1) { this.edit = true }
    if (this.permissions.rate_create == 1) { this.cerate = true }
    if (this.permissions.rate_delete == 1) { this.rateDelete = true }
  }

  ngOnInit() {
    this.getRate()
    this.userID = JSON.parse(localStorage.getItem('userData'));
    this.operative_id = this.userID.operative_id;
    this.getShift()
    this.getRoleSiteAdditionalSetting()
    this.registerForm = this.formBuilder.group({
      site_id: ['', Validators.compose([Validators.required])],
      shift_id: ['', Validators.compose([Validators.required])],
      role_id: ['', Validators.compose([Validators.required])],
      std_working_hours: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      basic_rate: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      overtime_rate: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      holiday_rate: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      sick_pay_rate: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      bank_holiday_rate: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      overtime_bank_holiday_rate: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      applicable_from: ['', Validators.compose([Validators.required])],
      // custom_uplift_rate: ['', Validators.compose([Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      // manager_uplift_rate: ['', Validators.compose([Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      // supervisor_uplift_rate: ['', Validators.compose([Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
      // custom_role_id: [''],
      // manager_role_id: ['2'],
      // supervisor_role_id: ['3'],
      updated_byuser: this.operative_id,
      transactionType: [''],
      rate_id: [''],
    });
  }
  addRate() {
    // console.log(this.registerForm.value);
    var date = this.datepipe.transform(this.registerForm.controls.applicable_from.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ applicable_from: date });
    this.rate.addRates(this.registerForm.value).subscribe((data: any) => {
      console.log(data);
      if (data.status == "success") {
        this.alertMsg.openAlert(data.data, 'success');
        this.router.navigate(['rate']);
      } else {
        this.alertMsg.openAlert(data.error, 'error');
        // this.registerForm.reset();
      }
    })
  }
  update(value: string) {
    var date = this.datepipe.transform(this.registerForm.controls.applicable_from.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ applicable_from: date });


    if (this.rateID) {
      this.Applicable_from = value;
      this.oldDate = this.datepipe.transform(this.rateDataById.applicable_from, 'dd/MM/yyyy');
      if (this.oldDate == this.Applicable_from) {
        this.registerForm.patchValue({ transactionType: 'update' })
      } else if (this.oldDate !== this.Applicable_from) {
        this.registerForm.patchValue({ transactionType: 'insert' })
      }
    }
  }
  onChangeSite(deviceValue) {
    if (this.rateID) {
      this.ID = deviceValue;
      if (this.rateDataById.site_id == this.ID) {
        this.registerForm.patchValue({ transactionType: 'update' })
      } else {
        this.registerForm.patchValue({ transactionType: 'insert' })
      }
    }
  }
  onChangeShift(deviceValue) {
    if (this.rateID) {
      this.ID = deviceValue;
      if (this.rateDataById.shift_id == this.ID) {
        this.registerForm.patchValue({ transactionType: 'update' })
      } else {
        this.registerForm.patchValue({ transactionType: 'insert' })
      }
    }
  }
  onChangeRole(deviceValue) {
    if (this.rateID) {
      this.ID = deviceValue;
      if (this.rateDataById.role_id == this.ID) {
        this.registerForm.patchValue({ transactionType: 'update' })
      } else {
        this.registerForm.patchValue({ transactionType: 'insert' })
      }
    }
  }
  getRate() {
    var data =
    {
      rate_id: this.route.snapshot.params['id'],
    }
    if (this.rateID) {
      this.rate.getRateById(data).subscribe((data: any) => {
        // console.log(data);
        if (data.status == "success") {
          var rateDetail = data.data;
          console.log(data);
          var rateByID = rateDetail.data
          var upliftRate = rateDetail.uplift_rate
          this.upliftRateByID = upliftRate
          this.rateDataById = rateByID;
          console.log(this.rateDataById);

          if (this.rateDataById.applicable_from && this.rateDataById.site_id && this.rateDataById.shift_id && this.rateDataById.role_id) {
            this.transaction_Type = "update"
          }
          this.oldDate = this.datepipe.transform(this.rateDataById.applicable_from, 'dd/MM/yyyy');
          if (this.rateDataById.allow_delete == "1") {
            this.editFalse = true;
          }
          this.registerForm.patchValue({
            site_id: this.rateDataById.site_id,
            shift_id: this.rateDataById.shift_id,
            role_id: this.rateDataById.role_id,
            std_working_hours: this.rateDataById.std_working_hrs,
            basic_rate: this.rateDataById.basic_rate,
            overtime_rate: this.rateDataById.overtime_rate,
            holiday_rate: this.rateDataById.holiday_rate,
            sick_pay_rate: this.rateDataById.sick_pay_rate,
            bank_holiday_rate: this.rateDataById.bank_holiday_rate,
            overtime_bank_holiday_rate: this.rateDataById.overtime_bank_holiday_rate,
            applicable_from: this.rateDataById.applicable_from,
            updated_byuser: this.operative_id,
            // manager_role_id: this.upliftRateByID[0].role_id,
            // manager_uplift_rate: this.upliftRateByID[0].uplift_rate,
            // supervisor_role_id: this.upliftRateByID[1].role_id,
            // supervisor_uplift_rate: this.upliftRateByID[1].uplift_rate,
            // custom_role_id: this.upliftRateByID[2].role_id,
            // custom_uplift_rate: this.upliftRateByID[2].uplift_rate,
            transactionType: this.transaction_Type,
            rate_id: this.rateID,
          });
        } else if (data.session == 0) {
          var loginErr_Mag = "Session expired. Redirect to login"
          this.alertMsg.openAlert(loginErr_Mag, 'error');
          localStorage.removeItem('userToken');
          localStorage.removeItem('userData');
          localStorage.removeItem('userRoles');
          localStorage.removeItem('permissions');
          window.location.reload();
        }
      });
    }
  }
  getShift() {
    var data =
      { "operative_id": "new" }
    this.rate.getShift(data).subscribe((data: any) => {
      // console.log(data.data);
      var DATA = data.data
      this.shiftList = DATA.shiftList
      // console.log(this.shiftList);
    })
  }
  getRoleSiteAdditionalSetting() {
    var data =
      { "operative_id": "new" }
    this.rate.newOperativ(data).subscribe((data: any) => {

      localStorage.setItem('userToken', data.token);
      this.addNew = data;
      this.role = this.addNew.data;
      this.roleList = this.role.roleList
      this.role2 = this.roleList.filter(role => role.role_id !== '3' && role.role_id !== '2')
      this.manager = this.roleList.filter(role => role.role_id == '2')
      this.managerID = this.manager[0].role_id;
      this.supervisor = this.roleList.filter(role => role.role_id == '3')
      this.supervisorID = this.supervisor[0].role_id;
      //for site
      this.site = this.addNew.data;
      this.siteList = this.site.siteList;
    });
  }
  Update() {
    console.log(this.registerForm.value);
    this.rate.updateRate(this.registerForm.value).subscribe((data: any) => {
      console.log(data);
      if (data.status == "success") {
        this.alertMsg.openAlert(data.data, 'success');
        this.router.navigate(['rate']);
      } else {
        this.alertMsg.openAlert(data.error, 'error');
        // this.registerForm.reset();
      }
    })
  }
  delete() {
    var data = {
      rate_id: this.rateID,
      updated_byuser: this.operative_id
    }
    this.rate.deleteRate(data).subscribe((data: any) => {
      if (data.status == "success") {
        this.alertMsg.openAlert(data.data, 'success');
        this.router.navigate(['rate']);
      } else {
        this.alertMsg.openAlert(data.data, 'error');
        this.registerForm.reset();
      }
    })

  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete();
      }
      this.dialogRef = null;
    });
  }



}
