import { activity } from './../activity.component';
import { ConfirmationModalComponent } from './../../shared/components/confirmation-modal/confirmation-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from './../../alert/alert.service';
import { DatePipe } from '@angular/common';
import { DataService } from './../../shared/service/data.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmationModalComponent>
  /** control for the MatSelect filter keyword */
  public activityFilter: FormControl = new FormControl();
  public contractFilter: FormControl = new FormControl();
  public message: string;
  stateCtrl = new FormControl();
  newAgency: any = 0;
  activity_hour: any;
  equipmentRequired: boolean = false;
  roleList: any;
  siteList: any;
  agencyList: any;
  operativeList: any;
  equipmentList: any;
  shiftList: any;
  activitiList: any;
  filter: any;
  contract_filter_List: any;
  isSeletedIndex: any;
  addNew: any;
  isLogtek: boolean = true;
  userName_Password: boolean = false;
  agencyOperativ: any;
  activityData: any;
  activity_by_id: any;
  isLogtek_user: any;
  userID: any;
  operative_id: any;
  operative_role: any;
  isSubmit: boolean = true;
  siteListBy_siteid: any;
  setSite: any;
  today: Date = new Date();
  date: any;
  Userole: any;
  //////
  isNotactivity_id: any;
  breakpoint: number;
  registerForm: FormGroup;
  options = [];
  equipmentList_by_contract_id = [];
  addActivity: any[];
  filteredOptions: Observable<string[]>;
  daily_activity_id: any;
  agencyID: boolean = false;
  activitiList_array: [];
  pageindex: any;
  pagesize: any;
  isActivityHours: boolean = false;
  isSubmitButton: boolean = true;
  isChangeSite: boolean = true
  permissions: any;
  edit: boolean = false;
  cerate: boolean = false;
  delete: boolean = false;
  contractList: any;
  constructor(
    private route: ActivatedRoute,
    private alertMsg: AlertService,
    public datepipe: DatePipe,
    private activity: DataService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.isNotactivity_id = 'new'
    this.pageindex = this.route.snapshot.queryParams['pageIndex']
    this.pagesize = this.route.snapshot.queryParams['pagesize']
    this.daily_activity_id = this.route.snapshot.params['id'];
    //////
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    if (this.permissions.activity_edit == 1) { this.edit = true }
    if (this.permissions.activity_create == 1) { this.cerate = true }
    if (this.permissions.activity_delete == 1) { this.delete = true }
    //////////////////
    this.userID = JSON.parse(localStorage.getItem('userData'));
    this.operative_id = this.userID.operative_id;
    this.operative_role = this.userID.role
    if (this.permissions.system_role == 'HR' || this.permissions.system_role == 'Admin') {
      this.isSubmit = false
    }

  }
  applyactivityFilter() {

    const filterValue = this.activityFilter.value.trim().toLowerCase();
    // console.log(filterValue);
    this.filter = this.activitiList.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
    // console.log(this.filter);
  }
  applyContractFilter() {
    const contractValue = this.contractFilter.value.trim().toLowerCase();
    // console.log(contractValue);
    this.contract_filter_List = this.contractList.filter(state => state.contract.toLowerCase().indexOf(contractValue) === 0);
  }


  async  ngOnInit() {
    this.getRoleSite();
    this.getActivity();
    this.date = this.datepipe.transform(this.today, 'yyyy-MM-dd');
    const site = JSON.parse(localStorage.getItem('userData'));
    // console.log(this.setActivity());

    if (this.permissions.system_role == 'Manager') {
      this.setSite = site.site_id;
      // console.log(this.setSite);
    } else if (this.permissions.system_role == 'Supervisor') {
      this.setSite = site.site_id;
      // console.log(this.setSite);
    } else {
      this.setSite = '';
      // console.log(this.setSite);
    }



    this.registerForm = this.formBuilder.group({
      daily_activity_id: [''],
      site_id: [this.setSite, Validators.compose([Validators.required])],
      shift_id: ['', Validators.compose([Validators.required])],
      activity_date: ['', Validators.compose([Validators.required])],
      is_logtek: [this.isLogtek],
      agency_id: [''],
      operative_id: ['', Validators.compose([Validators.required])],
      role_id: ['', Validators.compose([Validators.required])],
      activity_hours: this.formBuilder.array([]),
      submitted_to: [''],
      updated_byuser: site.operative_id,

    });
    if (!this.daily_activity_id) {
      for (let i = 0; i < 3; i++) {

        this.addCreds(i);
      }
      // this.isSubmitButton = false;
    }
    // await 
  }
  getRoleSite() {
    const site = JSON.parse(localStorage.getItem('userData'));
    const site_id = site.site_id
    if (!this.daily_activity_id) {
      var data =
      {
        "daily_activity_id": this.isNotactivity_id,
        "agency_id": this.newAgency,
        "site_id": site_id
      }
    } else if (this.daily_activity_id) {
      var data =
      {
        "daily_activity_id": this.daily_activity_id,
        "agency_id": this.route.snapshot.queryParams['a_id'],
        "site_id": this.route.snapshot.queryParams['site_id']
      }
    }
    // console.log(data);

    this.activity.activitySiteRoleList(data).subscribe((data: any) => {
      // console.log(data);
      if (data.status == "success") {
        localStorage.setItem('userToken', data.token);
        this.addNew = data.data;
        console.log(data);
        // for role
        this.equipmentList = this.addNew.equipmentList;
        this.roleList = this.addNew.roleList
        // console.log('hhhhhh');

        //for site
        this.siteList = this.addNew.siteList;
        if (this.permissions.system_role == 'Manager' || this.permissions.system_role == 'Supervisor') {
          this.siteListBy_siteid = this.siteList.filter(site => site.site_id == site_id)
        } else {
          this.siteListBy_siteid = this.siteList
        }
        //for agencyList
        this.agencyList = this.addNew.agencyList;
        // for operative
        this.operativeList = this.addNew.getOperativeList;

        //for shift list
        this.shiftList = this.addNew.shiftList;
        //
        // console.log('hello');

        this.contractList = this.addNew.contractList
        this.contract_filter_List = this.contractList
        //for activiti list
        this.activitiList = this.addNew.activityList
        this.filter = this.activitiList

        this.options = this.operativeList

      } else if (data.session == 0) {
        var loginErr_Mag = "Session expired. Redirect to login"
        this.alertMsg.openAlert(loginErr_Mag, 'error');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('userRoles');
        window.location.reload();
      }
    });

  }

  //delete activity

  deleteActivity() {
    var data = {
      "daily_activity_id": this.route.snapshot.params['id'],
      "updated_byuser": this.operative_id
    }
    this.activity.deleteActivity(data).subscribe((data: any) => {
      // console.log(data);

      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.router.navigate(['dailyActiviti']);
        this.message = data.data;
        this.alertMsg.openAlert(this.message, 'success')
      } else if (data.status == "failure") {
        this.message = data.error;
        this.alertMsg.openAlert(this.message, 'error')
      }
    }, error => {
      // console.log(error);
    });
  }
  // add activity start
  saveActivity() {
    var date = this.datepipe.transform(this.registerForm.controls.activity_date.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ activity_date: date });
    // console.log(this.registerForm.value);
    this.activity.addActivity(this.registerForm.value).subscribe((data: any) => {
      // console.log(data);
      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.message = data.data;
        this.alertMsg.openAlert(this.message, 'success');
        this.registerForm.reset();
      } else if (data.status == "failure") {
        this.message = data.error;
        this.alertMsg.openAlert(this.message, 'error');
      }
    })
  }
  // add activity end

  // get activity by id start
  async  getActivity() {
    const site = JSON.parse(localStorage.getItem('userData'));
    if (this.daily_activity_id) {
      var data =
      {
        "daily_activity_id": this.route.snapshot.params['id'],
        "agency_id": "0",
        "site_id": this.route.snapshot.queryParams['site_id']
      }
      // console.log(this.userID.role);

      if (this.permissions.system_role == 'HR') {
        this.isChangeSite = false
      } else {
        this.isChangeSite = true
      }
      this.activity.getActivity(data).subscribe((data: any) => {
        console.log(data);
        this.activityData = data.data;
        this.activity_by_id = this.activityData.data
        this.equipmentList = this.activityData.equipmentList;
        // console.log(this.activity_by_id);

        if (this.activity_by_id.is_logtek == 1) {
          this.isLogtek_user = true;
        } else if (this.activity_by_id.is_logtek == 0) {
          this.isLogtek_user = false;
        }

        ///////////////
        if (this.permissions.system_role == "Supervisor") {
          if (this.activity_by_id.submitted_to == 'HR' || this.activity_by_id.submitted_to == 'Manager') {
            this.registerForm.disable();
            this.isSubmitButton = false;
          }
        } else if (this.permissions.system_role == "Manager") {
          if (this.activity_by_id.submitted_to == 'HR') {
            this.registerForm.disable();
            this.isSubmitButton = false;
          }
        }
        this.registerForm = this.formBuilder.group({
          daily_activity_id: this.activity_by_id.daily_activity_id,
          site_id: this.activity_by_id.site_id,
          shift_id: this.activity_by_id.shift_id,
          activity_date: this.activity_by_id.date,
          is_logtek: this.isLogtek_user,
          agency_id: this.activity_by_id.agency_id,
          operative_id: this.activity_by_id.operative_id,
          role_id: this.activity_by_id.role_id,
          activity_hours: this.formBuilder.array([]),
          submitted_to: [''],
          updated_byuser: site.operative_id,
        });
        this.setActivity();
      });
    }
  }
  // get activity by id end

  //Activity array contorls start
  setActivity() {
    // console.log(this.equipmentList);

    const creds = this.registerForm.controls.activity_hours as FormArray;
    this.activitiList_array = this.activity_by_id.activity_hours;
    // console.log(this.activitiList_array);
    var i = 0;
    this.activitiList_array.forEach((x: any) => {
      creds.push(this.formBuilder.group({
        activity_id: x.activity_id,
        contract_id: x.contract_id,
        equipment_id: x.equipment_id,
        activity_hours: x.activity_hours
      }))
      if (this.equipmentList !== undefined || null) {
        this.equipmentList_by_contract_id[i] = this.equipmentList.filter(equipment => equipment.contract_id == x.contract_id)
        i++
      }
    })
  }


  addCreds(i) {
    const creds = this.registerForm.controls.activity_hours as FormArray;
    creds.push(this.formBuilder.group({
      activity_id: [''],
      contract_id: [''],
      equipment_id: [''],
      activity_hours: ['', Validators.compose([Validators.pattern(/^(\d{1,10}|\d{0,10}\.\d{1,2})$/)])],
    }));
    // console.log(creds.value);
    this.addActivity = creds.value
  }
  getControls() {
    return (<FormArray>this.registerForm.get('activity_hours')).controls;
  }
  // activity array control end

  // update activity start
  updateActivity() {
    var date = this.datepipe.transform(this.registerForm.controls.activity_date.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ activity_date: date });
    // console.log(this.registerForm.value);
    this.activity.updateActivity(this.registerForm.value).subscribe((data: any) => {
      // console.log(data);
      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.message = data.data;
        this.alertMsg.openAlert(this.message, 'success');
        // this.registerForm.reset();
      } else if (data.status == "failure") {
        this.message = data.error;
        this.alertMsg.openAlert(this.message, 'error');
      }
    })
  }
  // update activity end

  //submit activity start
  submitActivity() {
    if (this.permissions.system_role == 'Supervisor') {
      var submitted_to = "Manager"
    } else if (this.permissions.system_role == 'Manager') {
      var submitted_to = "HR"
    }
    var date = this.datepipe.transform(this.registerForm.controls.activity_date.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ activity_date: date });
    this.registerForm.patchValue({ submitted_to: submitted_to });
    // console.log(this.registerForm.value.activity_hours);
    this.activity_hour = this.registerForm.value.activity_hours
    let flag = 1;
    let count = 0;
    this.activity_hour.forEach(activity => {
      activity.activity_id = (activity.activity_id == undefined) ? "" : activity.activity_id;
      activity.activity_hours = (activity.activity_hours == null) ? "" : activity.activity_hours;

      console.log(activity.activity_id + " -- " + activity.activity_hours + " -- " + activity.contract_id)
      // console.log(flag + " == " + count);
      if (activity.activity_id !== "" || activity.activity_hours !== "" || activity.contract_id !== "") {
        if (activity.activity_id == "" || activity.activity_hours == "" || activity.contract_id == "") {
          flag = 0
        } else {
          count++
        }

      }
      console.log(flag + " == " + count);
    });
    if (flag && count > 0) {
      this.activity.addActivity(this.registerForm.value).subscribe((data: any) => {
        // console.log(data);
        localStorage.setItem('userToken', data.token);
        if (data.status == "success") {
          this.ngOnInit()
          this.isLogtek = true;
          this.message = data.data;
          // this.router.navigate(['dailyActiviti']);
          this.alertMsg.openAlert(this.message, 'success')
        } else if (data.status == "failure") {
          this.message = data.error;
          this.alertMsg.openAlert(this.message, 'error')
        }
      }, error => {
        // console.log(error);
      });
    } else {
      if (count <= 0) {
        this.alertMsg.openAlert('please select atleast one activity', 'error')
      } else {
        this.alertMsg.openAlert('Activity not selected', 'error')
      }
    }
  }
  // submit activity end
  updateSubmit() {
    if (this.permissions.system_role == 'Supervisor') {
      var submitted_to = "Manager"
    } else if (this.permissions.system_role == 'Manager') {
      var submitted_to = "HR"
    }
    this.registerForm.patchValue({ submitted_to: submitted_to });
    var date = this.datepipe.transform(this.registerForm.controls.activity_date.value, 'yyyy-MM-dd');
    this.registerForm.patchValue({ activity_date: date });
    // console.log(this.registerForm.value);
    this.activity.updateActivity(this.registerForm.value).subscribe((data: any) => {
      // console.log(data);
      localStorage.setItem('userToken', data.token);
      if (data.status == "success") {
        this.message = data.data;
        this.router.navigate(['dailyActiviti']);
        this.alertMsg.openAlert(this.message, 'success');
        // this.registerForm.reset();
      } else if (data.status == "failure") {
        this.message = data.error;
        this.alertMsg.openAlert(this.message, 'error');
      }
    })
  }

  /// udate submit activity


  ///

  // form onChange methods start
  onChangeRole(agency_id) {
    const site = JSON.parse(localStorage.getItem('userData'));
    const site_id = site.site_id
    console.log(agency_id);
    if (!this.daily_activity_id) {
      var data = {
        "agency_id": agency_id,
        "site_id": site_id
      }
    } else {
      var data = {
        "agency_id": agency_id,
        "site_id": this.route.snapshot.queryParams['site_id']
      }
    }
    console.log(data);

    this.activity.getAgencyOperativList(data).subscribe((data: any) => {
      console.log(data);
      this.agencyOperativ = data
      console.log(this.agencyOperativ);

      if (data.length == 0) {
        this.alertMsg.openAlert('Operative not found for this agency', 'info');
      }
    })
  }
  onChangeLogtek(isLogtek) {
    if (isLogtek = true) {
      this.registerForm.controls.operative_id.reset();
      this.registerForm.controls.agency_id.reset()
      this.userName_Password = false;
    }
  }
  onChangeNewRole(O_id: any) {

    console.log(O_id);
    if (this.isLogtek == true) {
      const valid = this.operativeList.filter((x: any) => x.operative_id == O_id)
      console.log(valid);
      const operativeList = this.roleList.filter((y: any) => y.name == valid[0].role)
      this.registerForm.patchValue({ role_id: operativeList[0].role_id });
    } else if (this.isLogtek == false) {
      const setRole = this.agencyOperativ.filter((x: any) => x.operative_id == O_id)
      console.log(setRole);
      const agencyOperativ = this.roleList.filter((y: any) => y.name == setRole[0].role)
      this.registerForm.patchValue({ role_id: agencyOperativ[0].role_id });
    }
  }
  //////////////////////////////////////////
  onSelectActivty(i) {
    this.filter = this.activitiList
    console.log(i);
    const valid = this.activitiList.filter((x: any) => x.activity_id == i)
    console.log(valid);
    if (i !== undefined) {

      this.hoursInput.setValidators(Validators.required);
      this.hoursInput.updateValueAndValidity();
      this.contractInput.setValidators(Validators.required)
      this.contractInput.updateValueAndValidity();
      if (valid[0].da_equipmnet_required == '1') {
        this.equipmentInput.setValidators(Validators.required);
        this.equipmentInput.updateValueAndValidity();
      } else {
        this.equipmentInput.setValidators(Validators.nullValidator);
        this.equipmentInput.updateValueAndValidity();
      }
    }
    else if (i == undefined) {
      this.equipmentInput.setValidators(Validators.nullValidator);
      this.equipmentInput.updateValueAndValidity();
      this.hoursInput.setValidators(Validators.nullValidator);
      this.hoursInput.updateValueAndValidity();
      this.contractInput.setValidators(Validators.nullValidator)
      this.contractInput.updateValueAndValidity();
    }
  }
  get equipmentInput() {
    return this.optionBExtra.get('equipment_id') as FormControl;
  }
  get contractInput() {
    return this.optionBExtra.get('contract_id') as FormControl;
  }
  get activitiInput() {
    return this.optionBExtra.get('activity_id') as FormControl;
  }
  get hoursInput() {
    return this.optionBExtra.get('activity_hours') as FormControl;
  }
  get optionBExtra() {
    return (<FormArray>this.registerForm.get('activity_hours')).controls[this.isSeletedIndex];
  }
  getIndex(index) {
    // console.log(index);
    this.isSeletedIndex = index;
  }
  onContract(i, index) {
    this.contract_filter_List = this.contractList
    console.log(i, index);

    // this.siteListBy_siteid = this.siteList.filter(site => site.site_id == site_id)
    this.equipmentList_by_contract_id[index] = this.equipmentList.filter(equipment => equipment.contract_id == i)
    console.log(this.equipmentList_by_contract_id[index]);

    if (this.equipmentList_by_contract_id[index].length == 0) {
      this.alertMsg.openAlert("Equipment type not found for this contract", 'info');
    }

  }
  //////////////////////////////////////
  funtionThatNeedsIndex(index) {
    return index;
  }
  // form onChange methods end

  // delete modal start
  openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteActivity();
      }
      this.dialogRef = null;
    });
  }
  // delete modal end
}