import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  hr: boolean = false;
  manager: boolean = false;
  supervisor: boolean = false;
  permissions: any;
  activity: boolean = false;
  operativ: boolean = false;
  rate: boolean = false;
  volume: boolean = false;
  constructor() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    if (this.permissions) {
      if (this.permissions.activity_read == 1) { this.activity = true }
      if (this.permissions.operative_read == 1) { this.operativ = true }
      if (this.permissions.system_role == 'HR') { this.rate = true }
      if (this.permissions.volume_read == 1) { this.volume = true }
      // if (this.permissions.system_role == 'Admin') {
      //   this.volume = true;
      //   this.activity = true;
      // }
    }
    var user = localStorage.getItem('userRoles')
    // console.log(user);
    if (user == 'HR') {
      this.hr = true;
    } else if (user == 'Manager') {
      this.manager = true;
    } else if (user == 'Supervisor') {
      this.supervisor = true;
    }
  }

  ngOnInit() {
  }

}
