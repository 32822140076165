import { Router } from '@angular/router';
import { Component } from '@angular/core';
// import { LocationStrategy } from '@angular/common';
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'logtekAMS';
  isloggedin: boolean;
  r: boolean = false

  constructor(private router: Router, private Location: PlatformLocation) {
  }

  ngAfterViewInit() {
    if (localStorage.getItem('userToken')) {
      this.isloggedin = true
    } else {
      this.isloggedin = false
    }
  }
}
