import { AuthGuard } from './auth/auth.guard';
import { AddOperativeComponent } from './operativ/add-operative/add-operative.component';
import { AddEdovolumeComponent } from './eod-volume/add-edovolume/add-edovolume.component';
import { AddActivityComponent } from './activity/add-activity/add-activity.component';
// import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { RateComponent } from './rate/rate.component';
import { RatedetailComponent } from './rate/ratedetail/ratedetail.component';
import { ActivityComponent } from './activity/activity.component';
import { EdoVolumeComponent } from './eod-volume/eod-volume.component';
import { OperativComponent } from './operativ/operativ.component';
import { RoleGuard } from './auth/role.guard';
import { HomeComponent } from './home/home.component';
import { PageGuard } from './auth/page.gaurd';
import { volumeGuard } from './auth/volumePage.gaurd';
import { OperativGaurd } from './auth/operativ.gaurd';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' }, // main 
  { path: 'login', component: LoginComponent, }, // LoginComponent 
  // { path: 'home', component: HomeComponent },// HomeComponent 
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'dailyActiviti', component: ActivityComponent, canActivate: [PageGuard, AuthGuard] },
  { path: 'dailyActiviti/addActiviti', component: AddActivityComponent, canActivate: [PageGuard, AuthGuard] },
  { path: 'edit-activiti/:id', component: AddActivityComponent, canActivate: [PageGuard, AuthGuard] },

  { path: 'rate', component: RateComponent, canActivate: [RoleGuard, AuthGuard] },
  { path: 'editRate/:id', component: RatedetailComponent, canActivate: [RoleGuard, AuthGuard] },
  { path: 'rate/add-new', component: RatedetailComponent, canActivate: [RoleGuard, AuthGuard] },

  { path: 'eod-volume', component: EdoVolumeComponent, canActivate: [volumeGuard, AuthGuard] },
  { path: 'eod-volume/addEod', component: AddEdovolumeComponent, canActivate: [volumeGuard, AuthGuard] },
  { path: 'editEod/:id', component: AddEdovolumeComponent, canActivate: [volumeGuard, AuthGuard] },

  { path: 'operative', component: OperativComponent, canActivate: [OperativGaurd, AuthGuard] },
  { path: 'operative/addOperative', component: AddOperativeComponent, canActivate: [OperativGaurd, AuthGuard] },
  { path: 'editOperative/:id', component: AddOperativeComponent, canActivate: [OperativGaurd, AuthGuard] },

  { path: "**", component: NotfoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
