import { Data } from './data.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  formData: Data;
  apiUrl = environment.baseURL;
  constructor(private http: HttpClient) { }
  public getToken(): string {
    return localStorage.getItem('userToken');
  }
  getOperativ(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'operatives', data, { headers: reqHeader })
  }
  newOperativ(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'get_operative', data, { headers: reqHeader })
  }
  addOperativ(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'add_operative', data, { headers: reqHeader })
  }
  editOperativ_byID(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'get_operative', data, { headers: reqHeader })
  }
  daleteOperativ(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'delete_operative', data, { headers: reqHeader })
  }
  updateOperativ(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'edit_operative', data, { headers: reqHeader })
  }
  ///RATES API CALL/////
  getRates() {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.get(this.apiUrl + 'rates', { headers: reqHeader })
  }
  addRates(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'add_rate', data, { headers: reqHeader })
  }
  getShift(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'get_rate', data, { headers: reqHeader })
  }
  getRateById(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'get_rate', data, { headers: reqHeader })
  }
  updateRate(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'edit_rate', data, { headers: reqHeader })
  }
  deleteRate(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'delete_rate', data, { headers: reqHeader })
  }

  /// activity
  activityList(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'daily_activities', data, { headers: reqHeader })
  }
  activitySiteRoleList(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'get_daily_activity', data, { headers: reqHeader })
  }
  getAgencyOperativList(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'get_agency_operative_list', data, { headers: reqHeader })
  }
  addActivity(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'add_daily_activity', data, { headers: reqHeader })
  }
  getActivity(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'get_daily_activity', data, { headers: reqHeader })
  }
  updateActivity(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'edit_daily_activity', data, { headers: reqHeader })
  }
  deleteActivity(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'delete_daily_activity', data, { headers: reqHeader })
  }
  submitActivity(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'submit_daily_activity', data, { headers: reqHeader })
  }

  ///EOD 
  getEOD(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'eod_volumes', data, { headers: reqHeader })
  }
  getEOD_byID(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'get_eod_volume', data, { headers: reqHeader })
  }
  addEodVolume(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'add_eod_volume', data, { headers: reqHeader })
  }
  updateEodVolume(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'edit_eod_volume', data, { headers: reqHeader })
  }
  daelete_EOD_ActivityList_byID(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'delete_eod_volume_activity', data, { headers: reqHeader })
  }
  daelete_EOD(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'delete_eod_volume', data, { headers: reqHeader })
  }


  ///bulk submit
  bulksbumit_activities(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'submit_daily_activities', data, { headers: reqHeader })
  }
  bulksbumit_eod(data: any) {
    var reqHeader = new HttpHeaders({ 'Token': this.getToken() });
    return this.http.post(this.apiUrl + 'submit_eod_volumes', data, { headers: reqHeader })
  }
}
