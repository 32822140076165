import { AlertService } from './../alert/alert.service';
import { Router } from '@angular/router';
import { UserService } from './../shared/userService/user.service';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public message: string;
  loginError: boolean = false;
  loginErrMag: any;
  loginErr_Mag: any;
  loginErrorMsg: any;
  resetPasswordDiv: boolean = false;
  loginDiv: boolean = true;
  roles: any;
  user: any = [];
  isLoginError: boolean = false;
  userName: any;
  hide = true;
  hr: any;
  manager: any
  password_successfully_changed: boolean = false;
  constructor(private userService: UserService,
    private router: Router,
    private alertMsg: AlertService,
  ) { }

  ngOnInit() {
    this.roles = localStorage.getItem('userToken')
    if (this.roles = 'role1') {
      this.router.navigate(['/home']);
    }
  }

  OnSubmit(username, password) {
    this.userName = username
    this.userService.userAuthentication(username, password).subscribe((data: any) => {
      console.log(data);

      this.user = data.data;
      if (data.status == "success") {
        localStorage.setItem('userData', JSON.stringify(this.user));
        localStorage.setItem('userToken', data.token);
        localStorage.setItem('userRoles', data.data.role)
        localStorage.setItem('permissions', JSON.stringify(this.user.permissions));
        window.location.reload();
      } else if (data.status == "redirect") {
        this.resetPasswordDiv = true;
        this.loginDiv = false
      } else {
        this.router.navigate(['/login']);
      }
    }, (err: HttpErrorResponse) => {
      this.loginErrorMsg = err
      this.loginErrMag = this.loginErrorMsg.error;
      this.loginErr_Mag = this.loginErrMag.error;
      this.alertMsg.openAlert(this.loginErr_Mag, 'error');
    });
  }
  restPass(password) {
    this.userService.resetPassword(this.userName, password, this.user.operative_id).subscribe((data: any) => {
      console.log(data);
      if (data.status == "redirect") {
        this.resetPasswordDiv = false;
        this.loginDiv = true;
        var msg = 'Your password has been reset successfully';
        this.alertMsg.openAlert(msg, 'success');
      } else if (data.status == "failure") {
        this.alertMsg.openAlert(data.error, 'error');
      }
    });
  }
}
